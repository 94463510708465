<template>
    <div id="top" style="background: #fff">
          <div class="fixed-top">
            <div id="top-header" class="header navbar navbar-default top-header">
                <div class="logo">
                    <router-link to="/"> 
                      <img :src="logo_url" alt="Logo" width="60"> 
                    </router-link> 
                    <span>Code Digest Ltd</span>
                </div>
                <div class="bars"  @click="showSidebar">
                    <i class="fas fa-bars"></i>
                </div>
                <div class="menu-bar">
                  <div class="menu">
                    <a href="#" @click.prevent="scrollToSection('top')">Home</a>
                  </div>
                  <div class="menu">
                    <a href="#" @click.prevent="scrollToSection('aboutus')">About Us</a>
                  </div>
                  <div class="menu">
                    <a href="#" @click.prevent="scrollToSection('core-values')">Core Values</a>
                  </div>
                  <div class="menu">
                    <a href="#" @click.prevent="scrollToSection('services')">Services</a>
                  </div>
                 
                  <div class="menu">
                    <a href="#" @click.prevent="scrollToSection('sample-products')">Portfolio</a>
                  </div>
                
                  <div class="menu">
                    <a href="#" @click.prevent="scrollToSection('the-team')">The Team</a>
                  </div>
                  <div class="menu">
                    <a href="#" @click.prevent="scrollToSection('clientele')">Clientele</a>
                  </div>
                </div>
                <div class="contact-phone">
                  <a href="#" @click.prevent="scrollToSection('contactus')"><i class="fa fa-phone"></i> Contact us today!</a>
                </div>
            </div>
          </div>
          
            <nav id="sidebar" class="sidebar navbar navbar-expand-sm bg-light">
              <ul class="navbar-nav">
                <span class="close" @click="close">x</span>
                <li class="nav-item">
                    <a class="nav-link" href="#services">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#aboutus">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#core-values">Core Values</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#services">Services</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#sample-products">Portfolio</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#the-team">The Team</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#clientele">Clientele</a>
                </li>
              </ul>
            </nav>
            <div class="banner">
              <div class="banner-text">
                <h1>Empowering Businesses<br> Through Code</h1>
                <p>&nbsp;&nbsp;Innovate. Code. Elevate.</p>
                <h4>&nbsp;&nbsp;We bring your vision to life.</h4>
              </div>
            </div>
            <div class="about">
              <div class="content-header">
                  <p id="aboutus">About Us</p> 
                  <p class="brief-statement">We are a forward-thinking software development company committed to delivering innovative digital solutions. Our expertise in cutting-edge technology and IT consulting empowers businesses to thrive in a fast-evolving digital world.</p>
              </div>

                <div class="about-banner">
                    <div class="about-items">
                        <p><i class="fas fa-exclamation-circle"></i><br>About</p>
                        <p class="items-text">
                            We are IT consulting firm dedicated to helping businesses harness the power of technology to achieve their goals.
                        </p>
                    </div>
                    <div class="about-items">
                        <p><i class="far fa-lightbulb"></i><br>Our Vision</p>
                        <p class="items-text">
                          To be the most trusted partner in digital transformation, delivering innovative software solutions and expert IT consulting that drive business success globally
                        </p>
                    </div>
                    <div class="about-items">
                        <p><i class="fas fa-compass"></i><br>Core Mission</p>
                        <p class="items-text">
                          To innovate and lead in software development, delivering cutting-edge solutions that empower businesses and individuals worldwide.
                        </p>
                    </div>
                    <p>
                    </p>
                </div>
            </div>
            <div class="core-values">
                <div class="content-header">
                    <p id="core-values">Core Values</p> 
                    <p class="brief-statement">
                        Integrity, Innovation, Excellence, Customer-Centricity, and Collaboration.  
                        We are committed to delivering cutting-edge solutions with honesty, creativity, and a focus on client success.
                    </p>
                </div>
                <div class="core-values-contents">
                    <div class="core-items">
                        <i class="fa fa-life-ring" aria-hidden="true"></i>
                        <p>Excellence</p>
                        <span class="core-tooltips"> We are committed to delivering top-notch solutions and services that exceed client expectations.
                        </span>
                    </div>
                    <div class="core-items">
                        <i class="fa fa-lightbulb" aria-hidden="true"></i>
                        <p>Innovation</p>
                        <span class="core-tooltips"> We embrace change and constantly seek innovative ways to solve complex business challenges.
                        </span>
                    </div>
                    <div class="core-items">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <p>Integrity</p>
                        <span class="core-tooltips"> We conduct business with the highest level of honesty, transparency, and ethics.
                        </span>
                    </div>
                    <div class="core-items">
                        <i class="fa fa-users" aria-hidden="true"></i>
                        <p>Collaboration</p>
                        <span class="core-tooltips"> We believe in working closely with clients to build strong, lasting partnerships.
                        </span>
                    </div>
                </div>
            </div>
            <div class="content-services">
              <div class="content-header">
                  <p id="services">Our Services</p> 
                  <p class="brief-statement">We provide a wide range of innovative solutions tailored to meet the evolving needs of businesses, from custom software development to IT consulting and digital transformation.</p>
              </div>

              <div class="content-row">
                  <div class="content-items">
                      <p><i class="fa fa-database" aria-hidden="true"></i>
                        <br>Software Development</p>
                      <p class="items-text">
                          Our team of experienced developers specializes in custom software development, creating tailored solutions to address unique business needs.
                      </p>
                  </div>
                  <div class="content-items">
                      <p><i class="fa fa-cubes" aria-hidden="true"></i>
                        <br>IT Consulting/Training</p>   
                      <p class="items-text">
                          We provide strategic IT consulting services, helping clients align technology with their business objectives.  Our Software Development Training is designed to offer an in-depth and affordable learning experience for those with a passion for IT. Whether you're a beginner looking to kickstart your career or an experienced professional aiming to enhance your skills, our training caters to your needs.
                      </p>       
                  </div>
                  <div class="content-items">
                      <p><i class="fas fa-wallet"></i> <br>Web and Mobile App Development</p>   
                      <p class="items-text">
                          We design and develop responsive, user-friendly web and mobile applications that enhance the digital presence of our clients.
                      </p>       
                  </div>
                  <div class="content-items">
                      <p><i class="fa fa-cloud" aria-hidden="true"></i>
                        <br>Data Analytics & Cloud Services </p>
                      <p class="items-text">
                          Our cloud experts assist clients in migrating to the cloud, optimizing their cloud infrastructure, and ensuring data security and compliance. Also We offer data analytics solutions to help clients make data-driven decisions and gain valuable insights from their data.
                      </p>
                  </div>
              </div>
               
            </div>
            <div class="the-team">
                <div class="content-header">
                    <p id="the-team">The Team</p> 
                    <p class="brief-statement">
                        Our team is a dynamic group of skilled professionals, dedicated to innovation and excellence. We collaborate to deliver top-notch solutions that drive success and growth.
                    </p>  
                </div>
                <div class="team-content-row">
                    <div class="team-content-items">
                      <v-img
                        class="magnify-img"
                        src="../assets/teams/julius.png"
                      ></v-img>
                        <p>Julius Fasema</p>
                        <p class="items-text">
                            Software Engineer
                        </p>
                    </div>
                    <!-- <div class="team-content-items">
                      <v-img
                      class="magnify-img"
                      src="../assets/teams/no_image.png"
                      ></v-img>
                        <p>Matthew Fasema</p>   
                        <p class="items-text">
                            Engineering
                        </p>       
                    </div> -->
                    <div class="team-content-items">
                        <v-img
                        class="magnify-img"
                        src="../assets/teams/no_image.png"
                        ></v-img>
                        <p>Damian Oguche</p>   
                        <p class="items-text">
                          Backend Engineer
                        </p>       
                    </div>
                    <div class="team-content-items">
                      <v-img
                      class="magnify-img"
                      src="../assets/teams/chidiebere.png"
                      ></v-img>
                        <p>Chidiebere Onyekachi </p>
                        <p class="items-text">
                          Fullstack Developer
                        </p>
                    </div>
                    <div class="team-content-items">
                      <v-img
                      class="magnify-img"
                      src="../assets/teams/no_image.png"
                      ></v-img>
                      <p>Angela </p>
                      <p class="items-text">
                          UI/UX
                      </p>
                  </div>
                </div>
            </div>
            <div class="sample-products">
              <div class="content-header">
                  <p id="sample-products">Portfolio</p> 
                  <p class="brief-statement">
                      Explore our portfolio of innovative software solutions, cutting-edge applications, and successful digital transformations 
                      that showcase our expertise in delivering high-quality, scalable, and user-friendly products.
                  </p>
              </div>

                <!-- Carousel for Featured Projects -->
                <div class="sample-products-content-row">
                  <v-carousel cycle height="350" hide-delimiter-background show-arrows="hover">
                    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover>
                      <a target="_blank" :href="item.website">
                      <div class="slider">
                        <div class="text-white text-xl font-bold">
                          {{ item.title }}
                        </div>
                        <div class="sample-product-subtitle text-white text-sm">
                          {{ item.subtitle }}
                        </div>
                      </div>
                    </a>
                    </v-carousel-item>
                  </v-carousel>
                </div>

            </div>
            <div class="clientele">
                <div class="content-header">
                    <p id="clientele">Clientele</p> 
                    <p class="brief-statement">
                        We are proud to serve a diverse range of clients, from startups to established enterprises, delivering tailored solutions that meet their unique business needs.
                    </p> 
                </div>
                <div class="clientele-contents">
                  <div class="client-img"><img src="../assets/clients/plepower.png" alt="Plepower"></div>
                    <div class="client-img"><img src="../assets/clients/focushotel.png" alt="Focus Hotel"></div>
                    <div class="client-img"><img src="../assets/clients/dgn.png" alt="DGN RavePay"></div>
                    <div class="client-img"><img src="../assets/clients/mojilla.png" alt="Mojilla"></div>
                    <div class="client-img"><img src="../assets/clients/fordax.svg" alt="Fordax"></div>
                    <div class="client-img"><img src="../assets/clients/dolphin.jpeg" alt="Dolphin"></div>
                    <div class="client-img"><img src="../assets/clients/beckyjhair.png" alt="Becky J Hair"></div>
                    <div class="client-img"><img src="../assets/clients/skillpath.png" alt="SkillPath International school"></div>
                </div>
            </div>
            <div id="contactus">
              <div class="content-footer">
                  <div class="contact-info">
                      <img src="logo.png" alt="">
                      <p>We are a forward-thinking software development company committed to delivering innovative digital solutions..</p>
                  </div>
                  <div class="contact-info">
                      <p class="contact-title">contact info</p>
                      <p><i class="fa fa-map-marker" aria-hidden="true"></i>Model Plaza, Aso Road, Mararaba.</p>
                      <p><i class="fa fa-phone"></i> 07069500520, 08133519219</p>
                      <p><i class="fa fa-info-circle"></i> Have questions? Click the WhatsApp icon to reach our support team.</p>

                  </div>
                  <div class="contact-info">
                      <p class="services-title">Useful Links</p>
                      <p><a href="#">Project Topics</a> </p>
                      <p><router-link to="#">Blogs</router-link></p>
                      <p><router-link to="#"></router-link></p>
                  </div>
                  <div class="contact-info">
                      <p class="services-title">Socials</p>
                      <p> <span><a href="https://www.facebook.com/profile.php?id=61565893953175" target="_blank"><i class="fab fa-facebook-square"></i> Facebook</a></span> </p>
                      <p> <span><a href="https://linkedin.com/company/code-digest" target="_blank"><i class="fab fa-linkedin"></i> LinkedIn </a></span></p>
                      <!-- <p> <span><i class="fa fa-instagram"></i> Instagram</span></p> -->
                  </div>
              </div>
              <div class="footers">
                  <p class="copyright">&copy;{{ new Date().getFullYear() }} Code Digest Limited. All rights reserved. </p>
              </div>
            </div>  
            <a href="https://wa.me/2347069500520" target="_blank" class="whatsapp-float">
                <i class="fab fa-whatsapp"></i>
            </a>
            <div class="top">
              <a href="#top">
                <i class="fa fa-arrow-up"></i>
              </a>
            </div>
    </div>
</template>
<script>
//import swal from 'sweetalert2';
import $ from 'jquery';

export default {
    
    data() {
    return {
        logo_url: process.env.VUE_APP_API_LOGO,
        banner_url: process.env.VUE_APP_API_BANNER,
        company_phone: process.env.VUE_APP_COMPANY_PHONE,
        company_email: process.env.VUE_APP_COMPANY_EMAIL,
        company_name: process.env.VUE_APP_COMPANY,
        company_address: process.env.VUE_APP_COMPANY_ADDRESS,
        register_modal: false,
        loading: false,
        firstname: '',
        middlename: '',
        lastname: '',
        phone_number: '',
        email_address: '',
        gender: '',
        password: '',
        confirm_password: '',
        show: false,
        items: [
        {
          src: "https://dailychamp.ng/img/2.1d977a36.png",
          website: "https://dailychamp.ng",
          title: "E-Commerce Platform",
          subtitle: "A scalable multi-vendor marketplace with installment payments."
        },
        {
          src: "http://reyunionpoint.com/img/login-banner.f44e6f2b.jpg",
          website: "http://reyunionpoint.com",
          title: "Reunion App",
          subtitle: "A social networking platform connecting people through shared interests."
        },
        {
          src: "https://zedlocker.com/wp-content/uploads/elementor/thumbs/Zedlock-logo-send-c-qqa8dl3ezpxraz4zgkr2i146hcmp8gqeb4ore47d98.jpg",
          website: "https://zedlocker.com",
          title: "ZEDLOCKER",
          subtitle: "An escrow system ensuring secure transactions between buyers and sellers."
        },
        {
          src: "https://via.placeholder.com/800x600?text=Project+5",
          website: "https://zedlocker.com",
          title: "Enterprise Banking API",
          subtitle: "A secure API for processing agency banking transactions seamlessly."
        },
        {
          src: "https://skillpathschools.com/img/bg3.ee8f528f.jpg",
          website: "https://skillpathschools.com",
          title: "School Management Solution",
          subtitle: "A comprehensive platform for managing schools efficiently.."
        },
        {
          src: "https://class.fordaxbschool.com/assets/FBSlogo-D6aP31so.svg",
          website: "https://fordaxbschool.com",
          title: "Fordax Business School",
          subtitle: "An innovative e-learning and business school solution.."
        },
        {
          src: "https://dgnravepay.com/assets/img/Group%201000005408%20(1).png",
          website: "https://dgnravepay.com",
          title: "Agency Banking Solution",
          subtitle: "A secure and scalable agency banking platform.."
        },
        {
          src: "https://plepower.com.ng/img/bg.8dd41cc7.png",
          website: "https://plepower.com.ng",
          title: "PlePower",
          subtitle: "A powerful tool for data, airtime, cabletv and electricity subscriptions"
        },
        {
          src: "https://staging.codedigest.com.ng",
          website: "https://staging.codedigest.com.ng/login",
          title: "Inventory Control System",
          subtitle: "Streamlining inventory management for seamless operations and efficiency"
        }
        
      ],
        pageTitle: 'Home | Code Digest Limited',
        model: null,
    };
  },

    beforeMount(){
       
    },
    mounted() {
        this.showDiv()
        $(document).ready(function() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.top').fadeIn();
           document.getElementById('top-header').style.display = 'flex'
          
        } else {
            $('.top').fadeOut();
            if ($(this).scrollTop() == 0) {
                document.getElementById('top-header').style.display = 'flex'
            }
        }
       
    });

    $('.top').click(function() {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
        });
    });

    setInterval(() => {
        this.showImage = this.showImage === 2 ? 1 : this.showImage + 1;
    }, 10000); // Toggle images every 3 seconds

    },
    created(){
        document.title = this.pageTitle;
    },

  methods: {
    
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },

    showDiv() {
      this.show = true;
    },
    showSidebar() {
        var x = document.getElementById("sidebar");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    },
    close(){
        var x = document.getElementById("sidebar");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    },  

  },
    
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 1.2s;
  }
  .modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
    opacity: 0.5;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
.header {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 70px;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
    font-family: Mulish;
}
.fixed-top{
    z-index: 9999;
    background: #fff;
}
.logo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    font-family: Mulish;
    color: #04182e84;
    text-shadow: 0 0 0px #216ab8d2;
}
.sidebar{
    display: none;
}
.nav-item{
  padding: 5px;
  font-size: 16px;
  font-weight: 700;
  font-family: Mulish;
}
.nav-item:hover{
  background: #216ab8d2;
  color: #fff;
  margin: 0;
}
.nav-link:hover{
  color: #fff;
}
.navbar-nav{
  padding-left: 0px;
}
.bars {
    display: none;
}
.menu-bar {
    display: flex; 
    flex-direction: row; 
    justify-content: space-around; 
    width:50%;
    font-family: Mulish;
}
.contact-phone{
    width: auto;
    height: auto;
    background: #216ab8d2;
    font-weight: 700;
    border-radius: 4px;
    font-family: Mulish;
    font-size: 13px;
    padding: 0.4rem;
    a {
        color: #fff;
        text-decoration: none;
    }
}
.menu{
    font-size: 15px !important;
    font-weight: 550;
    text-transform:capitalize;
    text-shadow: 0px 0px #000;
    font-family: Mulish;
    padding: 0.4rem;
}
.menu > a {
    color: #216ab8d2;
}
.menu:hover {
    cursor: pointer;
}
.banner{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    height: 750px;
    bottom: 0;
    font-family: Mulish;
    background: url(../assets/bg.jpg) no-repeat;
    background-size:100%;
    color: #fff;
    padding: 0 50px 0 0px;
}
.banner-text{
    display: flex;
    flex-direction: column;

    h1{
      font-size: 60px;
    }
    p{
      font-size: 20px;
    }
    h4{
      background: #000;
      color: #fff;
      font-size: 30px;
      width: 60%;
      border-radius: 5px;
    }
}
.action-botton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.action-botton2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.content-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
    font-family: Mulish;
    font-size:25px;
    text-decoration: none;
    color: #031425f8;
    text-decoration-color: #216ab8d2;
    text-decoration-thickness: 4px; 
    text-underline-offset: 10px;
    font-weight: 800;
    text-transform: none;
    width: 50%;
    .brief-statement{
      font-size:16px;
      font-style: italic;
      text-align: center;
      font-weight: 500;
    }
  }
  .content-services{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    background: #216ab811;
  }

  .sample-products{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    background: #fff;
  }
  .content-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
  .sample-products-content-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: auto;

    a{
      text-decoration: none;
    }

    .slider {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color:#00000021; 
      height: 350px; 
      font-size: 45px; 
      font-weight: bold;
      text-shadow: 0px 1px #000; 
      font-family: Mulish;
    }
    .sample-product-subtitle{
      font-size: 18px !important; 
      text-shadow: 0px 0px #f5f9f711; 
      text-align: center;
      width: 40%;
      background-color:#000; 
      border-radius: 10px;
    }
    
  }
  .content-items{
    width: 100%;
    height: 350px;
    border: 0px solid #216ab8d2;
    box-shadow: -1px -1px 4px #ccc;
    border-radius: 4px;
    margin: 15px;
    padding: 15px;
    text-align: center;
  
    p{
      font-family: Mulish;
      font-size:13px;
      font-weight: 700;

      .fa-database{
        font-size:50px;
        color: #216ab8d2;
      }
      .fa-wallet{
        font-size:50px;
        color: #216ab8d2;
      }
      .fa-cubes{
        font-size:50px;
        color: #216ab8d2;
      }
      .fa-cart-plus{
        font-size:50px;
        color: #216ab8d2;
      }
      .fa-drafting-compass{
        font-size:50px;
        color: #216ab8d2;
      }
      .fa-certificate{
        font-size:50px;
        color: #216ab8d2;
      }
      .fa-cloud{
        font-size:50px;
        color: #216ab8d2;
      }
    }
    .items-text {
      font-family: Mulish;
      font-size:13px;
      font-weight: 450;
      text-align: justify;
    }
  
  }
  .the-team{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
  }
  .team-content-row{
    margin:7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .team-content-items{
    box-sizing: border-box;
    border: 1px solid #216ab8d2;
    box-shadow: -1px -1px 4px #ccc;
    border-radius: 4px;
    margin: 10px;
    margin-top: -20px;
    padding: 15px;
    text-align: center;
    width:210px;

    .magnify-img {
      cursor: pointer;  
      transform: scale(0.9); 
      transition: transform 0.3s ease; 
      height:190px;
      width:200px;
      text-align: center;
    }
  
    p{
      font-family: Mulish;
      font-size:12px;
      font-weight: 500;
      line-height: 1px;
    }
    .items-text {
      line-height: 1px;
      font-family: Mulish;
      font-size:11px;
      font-weight: 700;
      text-align: center;
    }
  
  }
  .about{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    height: 500px;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    background: url(../assets/about.jpg) no-repeat ;
    background-size: cover;
    opacity: 0.8;
    margin-top: 0px;
    background-attachment: fixed; 
  }
  .about-banner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    width: 100%;
    color: #000;
  }
  .about-items{
    box-sizing: border-box;
    width: 260px;
    height: 260px;
    border: 1px solid #216ab8d2;
    box-shadow: -1px -1px 4px #ccc;
    background: #FFF;
    border-radius: 450px;
    margin: 10px;
    padding: 20px;
    text-align: center;
    
    p{
      font-family: Mulish !important;
      font-size:13px;
      font-weight: 700;

      .fa-compass{
        font-size:30px;
        color: #216ab8d2;
      }
      .fa-exclamation-circle{
        font-size:30px;
        color: #216ab8d2;
      }
      .fa-lightbulb{
        font-size:30px;
        color: #216ab8d2;
      }
    }
    .items-text {
      font-family: Mulish;
      font-size:11px;
      font-weight: 700;
      text-align: center;
    }
  
  }
  .core-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    height: auto;
    bottom: 0;
    font-family: Mulish, sans-serif;
    font-size: 10px;
    background: #FFF;
    margin-top: 0px;
    //box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Bottom shadow */
}

  .clientele{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    background: #FFF;
    margin-top: 0;
  }
  .core-values-contents{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-top: -30px;
    font-family: Mulish;
  }

  .clientele-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    padding: 30px;
    
    /* Optional: Scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
}

/* Hide scrollbar for Chrome, Safari */
.clientele-contents::-webkit-scrollbar {
    height: 5px;
}

.clientele-contents::-webkit-scrollbar-track {
    background: transparent;
}

.clientele-contents::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

/* Optional: Auto-scroll effect */
@keyframes scrollClientele {
    100% { transform: translateX(0); }
    100% { transform: translateX(-10%); }
}

.clientele-contents {
    //animation: scrollClientele 5s linear infinite alternate;
}

.client-img img {
    width: 150px; /* Adjust size */
    height: auto;
    object-fit: contain;
    border: solid 1px;
    border-radius: 5px;
    padding: 0.3rem;
}
  .client-img{
    img{
       width: 120px;
       height: 120px;
       border-radius: 10px;
    }
  }

  .clientele-items{
    width: 150px;
    height: 150px;
    border-radius: 47px;
    margin: 10px;

    img{
        width: 60%;
    }
    .innovation{
        width: 75% !important;
    }
    
    p{
      font-family: Mulish;
      font-size:15px;
      font-weight: 700;
      color: #216ab8d2;
    }
    .core-tooltips{
        display: none;
    }

  }
  .core-items{
    width: 150px;
    height: 150px;
    border-radius: 47px;
    margin: 10px;
    color: #216ab8d2;

    .innovation{
        width: 75% !important;
    }
    
    p{
      font-family: Mulish;
      font-size:15px;
      font-weight: 700;
      color: #216ab8d2;
    }
    .core-tooltips{
        display: none;
    }
    .fa-life-ring{
      font-size:50px;
    }
    .fa-lightbulb{
      font-size:50px;
    }
    .fa-check-circle{
      font-size:50px;
    }
    .fa-users{
      font-size:50px;
    }

  }
  .core-items:hover {
    .core-tooltips{
        cursor: pointer;
        display: block;
        font-size: 10px;
        font-family: Mulish;
        margin-top: -130px;
        text-align:justify;
        background: #216ab8d2;
        color: #FFF;
        border-radius: 4px;
        padding: 10px;
        width: 100%;
    }
  }

  .content-footer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    width: 100%;
    height: 250px;
    padding: 10px 20px 0 20px;
    background: #216ab8d2;
    color: #fff;
  }
  .content-footer-text{
    font-family: Mulish;
    font-size:12px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    color: #fff;
  }
  
  .contact-info{
    margin: 10px;
    width: 100%;

    img{ width:20% }

    p{
        font-family: Mulish;
        font-size:12px;    
    }
    a{
        text-decoration: none;
        color: #fff;
    }
  }
  .contact-title{
    font-size: 18px !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
  }

  .services-title{
    font-size: 18px !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
  }

  .copyright{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    font-family: Mulish;
    font-size:13px;
    font-weight: 400;
    text-align: center;
    
  }
  .footers{
    
  }
  .input-control {
    height: 30px;
    font-size: 12px;
    font-family: Mulish;
}
.password-check{
    margin-left:50px;
    font-size: 10px;
    font-family: Mulish;
    .five-char {
        color: black
    }
    .uppercase{
        color: black
    }
    .number{
        color: black
    }
    .special-char{
        color: black
    }
    .password-match{
        color: black
    }
}
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f9f711;
    display: table;
    transition: opacity .5s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #216ab8d2;
        background: #f2f3f2;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}
.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #FFF;
    }

    display: none;
}
.top{
  position: fixed;
  bottom: 80px; /* Adjust the distance from the bottom as needed */
  right: 20px;
  z-index: 9999;
  background: #216ab8d2;
  color: #fff;
  border-radius: 4px;
  padding: 15px;

  a{
    color: #fff;
  }
}

.whatsapp-float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    font-size: 24px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
}

.whatsapp-float:hover {
    transform: scale(1.1);
}


@media (min-width:320px) and (max-width: 720px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: Mulish;
    height: 70px;
    font-size: 12px;
}
.contact-phone{
    display: none;
    font-weight: 500;
    border-radius: 4px;
    font-family: Mulish;
    font-size: 12px;
    text-align: center;
    padding: 0.3rem;
    width: auto;
}
.content-header{
    margin: 20px;
    font-family: Mulish;
    font-size:20px;
    font-weight: 500;
    text-transform: none;
    width: 90%;
    .brief-statement{
      font-size:12px;
      font-style: italic;
      text-align: center;
    }
  }

    .sidebar {
        width: 150px;
        height: auto;
        position: fixed;
        z-index: 9998;
        transition: opacity .4s ease;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0px;
        right: 0px;
        padding: 0;

        a{
            color: #216ab8d2;
        }
        .close{
            margin-left: 20px;
        }
      }
      .sidebar a {float: left;}
      div.content {margin-left: 0;}

    .logo{
        font-size: 16px;
        font-weight: 700;
        font-family: Mulish;
        display: flex;
    }
    .bars {
        font-size: 30px;
        display: block;
        cursor: pointer;
        color: #216ab8d2;
    }
    .menu {
        display: none;
    }
    .banner{
      height: 100%;
      padding: 100px 50px 0px 0px;
    }
    .banner-text{
      display: flex;
      flex-direction: column;
      justify-content: center;

    h1{
      font-size: 27px;
    }
    p{
      font-size: 14px;
    }
    h4{
      background: #000;
      color: #fff;
      font-size: 18px;
      width: 80%;
      border-radius: 5px;
    }
}
    .action-botton{
        margin-top: 50px;
    }
    .action-botton2{
        margin-top: 50px;
    }
    .content-services{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

      }
      .sample-products{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .sample-products-content-row{

        .slider {

          height: 400px; 
          font-size: 30px; 
          font-weight: bold;
          text-shadow: 0px 1px #000; 
          font-family: Mulish;
          text-align: center;
        }
        .sample-product-subtitle{
          font-size: 13px !important; 
          text-shadow: 0px 0px #f5f9f711; 
          text-align: center;
          width: 60%;
        }
        
      }
      .the-team{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .team-content-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        width: 100%;
      }

      .content-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.9rem;
      }
      .content-items{
        box-sizing: border-box;
        width: 90%;
      }
      .team-content-items{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 220px;
        border: 1px solid #216ab8d2;
        box-shadow: -1px -1px 4px #ccc;
        border-radius: 4px;
        margin: 10px;
        padding: 15px;
        text-align: center;
    
        .magnify-img {
          cursor: pointer;  
          transform: scale(0.9); 
          transition: transform 0.3s ease; 
          height:100%;
          width:100%;
          text-align: center;
        }
      
        p{
          font-family: Mulish;
          font-size:15px;
          font-weight: 500;
          line-height: 1px;
        }
        .items-text {
          line-height: 1px;
          font-family: Mulish;
          font-size:15px;
          font-weight: 700;
          text-align: center;
        }
      
      }
      .about{
        padding: 10px;
        height: 100%;
        margin-top: 0px;
      }

      .about-banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        text-align: center;
        width: 100%;
        
      }
      .clientele-contents{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;
        text-align: center;
        width: 100%;
        font-family: Mulish;
        overflow-x: auto;
      }
      .client-img{
      img{
        width: 200px;
        height: 150px;
        border-radius: 10px;
      }
    }
      .core-values{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        height: auto;
        bottom: 0;
        font-family: Mulish;
        font-size: 10px;
        background: #FFF;
        margin-top: 0px;
      }
      .core-values-contents{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        text-align: center;
        width: 80%;
        margin-top: -30px;
        font-family: Mulish;
      }
      .core-items:hover {
        .core-tooltips{
            cursor: pointer;
            display: block;
            font-size: 10px;
            font-family: Mulish;
            margin-top: -130px;
            text-align:justify;
            background: #216ab8d2;
            color: #FFF;
            border-radius: 4px;
            padding: 10px;
            width: 100%;
        }
      }
      .content-footer {
        display: flex;
        flex-direction: column;
        bottom: 0;   
        height: auto;
      }
      .content-footer-text{
        font-family: Mulish;
        font-size:13px;
        font-weight: 500;
        text-align: center;
        word-wrap: break-word;
        width: 28em; 
        color: #000;
      }
      .social-media{
        display: flex;
        flex-direction: row;
        justify-content: center;
      
        p{
          font-size: 20px; 
        }
      }

      .contact-input{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
      .socialmedia-phone{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      }
      .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 241, 241, 0.5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #216ab8d2;
            background: #f4f7f5;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: none;
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: block;
    }

}
</style>