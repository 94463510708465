import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "../views/HomePage.vue"


Vue.use(VueRouter);
// parse jwt token
// function parseJwt (token) {
//   var base64Url = token.split('.')[1];
//   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
//       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));

//   return JSON.parse(jsonPayload);
// }

// function beforeRouteEnter (to, from, next) {
//   try{
//   if (localStorage.getItem('wertyuiojhdfghhdrtfyguh')) {
//     const jwtPayload = parseJwt(localStorage.getItem('wertyuiojhdfghhdrtfyguh'));
//     if (jwtPayload.exp < Date.now()/1000) {
//         // token expired
//         //deleteTokenFromLocalStorage();
//         next("/");
//     }
//     next();
//   } else {
//     next("/");
//   }
//   }catch (err) {
//     console.log(err);
//   }
// }

/*
function guardMyroute(to, from, next)
{
    var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
    if(localStorage.getItem('wertyuiojhdfghhdrtfyguh'))
      isAuthenticated = true;
    else
      isAuthenticated= false; if(isAuthenticated) 
    {
      next(); // allow to enter route
    } 
    else
    {
      next('/'); // go to '/login';
    }
    //next("/business-card");
}
*/

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
